import Mock from "../mock";

const database = {
  information: {
    name: "Michael Elliott",
    aboutContent:
      "Meticulous and innovative Software Engineer aimed to master latest tools and comprehension of modern practices in the software/application development lifecycle – from concept through delivery of websites/applications with the best user experience.",
    // age: 20,
    phone: "",
    // nationality: "",
    language: "English, Spanish",
    email: "",
    // address: "2350 s race st, Denver CO - 80210 - USA",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "",
      twitter: "",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/mellio/",
      dribbble: "",
      github: "https://github.com/melliottgithub",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile:
      "https://docs.google.com/document/d/1JPclAvGAU8ALf2CEbYTf8qKLJtKVmsiv/edit?usp=sharing&ouid=115028027274318427445&rtpof=true&sd=true",
  },
  services: [
    {
      title: "Web Design",
      icon: "pallet",
      details:
        "Design is not only what a website looks like and feels on the front-end. Design surrounds the internal functionality of a website as well as the overall UX.",
    },
    {
      title: "Web Development",
      icon: "code",
      details:
        "I am passionate about web development with a strong interest in mobile apps. As a developer, I aspire to write consistent, clear, elegant and readable code. ",
    },
    {
      title: "Engineering",
      icon: "construction-hammer",
      details:
        "As an engineer, I will carry the curiosity, critical thinking, collaborative spirit, creativity, effective communication and more to my new career. ",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "HTML5",
      value: 100,
    },
    {
      title: "CSS3",
      value: 100,
    },
    {
      title: "Javascript ES6+",
      value: 100,
    },
    {
      title: "ReactJS",
      value: 100,
    },
    {
      title: "Typescript",
      value: 100,
    },
    {
      title: "Python",
      value: 100,
    },
    {
      title: "Pandas",
      value: 100,
    },
    {
      title: "Git",
      value: 100,
    },
    {
      title: "Github/Actions",
      value: 100,
    },
    {
      title: "AWS:",
      value: 100,
    },
    {
      title: "S3",
      value: 100,
    },
    {
      title: "EC2/ECS",
      value: 100,
    },
    {
      title: "SQS",
      value: 100,
    },
    {
      title: "SES",
      value: 100,
    },
    {
      title: "ELB",
      value: 100,
    },
    {
      title: "Lambda",
      value: 100,
    },
    {
      title: "Route 53",
      value: 100,
    },
    {
      title: "SNS",
      value: 100,
    },
    {
      title: "Cloudformation",
      value: 100,
    },
    {
      title: "Cloudfront",
      value: 100,
    },
    {
      title: "IAM",
      value: 100,
    },
    {
      title: "Certificate Manager",
      value: 100,
    },
  ],
  portfolios: [
    {
      id: 7,
      title: "Text Analytics",
      subtitle: "Machine Learning/Data Science",
      imageUrl: "/images/about-image.jpg",
      // largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
      //url: "https://javascript.devmellio.com/",
      github: "https://github.com/melliottgithub/text-analytics",
    },
    {
      id: 8,
      title: "Ml models",
      subtitle: "Machine Learning/Data Science",
      imageUrl: "/images/about-image.jpg",
      // largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
      url: "https://javascript.devmellio.com/",
      github: "https://github.com/melliottgithub/ml-models",
    },
    {
      id: 9,
      title: "GPT-4",
      subtitle: "OpenAI GPT-4",
      imageUrl: "/images/about-image.jpg",
      // largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: "https://gpt4.devmellio.com/",
      github: "https://github.com/melliottgithub/gpt",
    },
    {
      id: 10,
      title: "Translation App",
      subtitle: "AWS, GCP, OpenAI APIs/Services",
      imageUrl: "/images/about-image.jpg",
      // largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: "https://aws.devmellio.com/",
      // github: "https://github.com/melliottgithub/gpt",
    },
    // {
    //   id: 1,
    //   title: "Ml Models",
    //   subtitle: "Data Science/Machine learning",
    //   imageUrl: "/images/about-image.jpg",
    //   // largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "https://aws.devmellio.com/",
    // },
    {
      id: 20,
      title: "Demo App",
      // stack: "Typescript, NodeJS, PostgresSQL, AWS",
      subtitle: "Typescript, NodeJS, PostgresSQL, AWS",
      imageUrl: "/images/about-image.jpg",
      // url: "https://test.devmellio.com/",
      github: "https://github.com/melliottgithub/demo-ncs",
      // github: "",
    },
    {
      id: 30,
      title: "Activities API",
      subtitle: "Typescript Service",
      imageUrl: "/images/about-image.jpg",
      //largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: "https://github.com/UDmonia/accounts-api",
      github: "https://github.com/UDmonia/accounts-api",
    },
    {
      id: 40,
      title: "Contacts API",
      subtitle: "Typescript Service",
      imageUrl: "/images/about-image.jpg",
      // largeImageUrl: [
      //   "/images/portfolio-image-4-lg.jpg",
      //   "/images/portfolio-image-4-lg2.jpg",
      // ],
      url: "https://github.com/UDmonia/activities-api",
      github: "https://github.com/UDmonia/activities-api",
    },
    // {
    //   id: 6,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/Photo-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
    // },
    // {
    //   id: 7,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: "https://twitter.com",
    // },
    // {
    //   id: 8,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"],
    // },
    // {
    //   id: 9,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    // },
    // {
    //   id: 10,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "#",
    // },
    // {
    //   id: 11,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg",
    //   ],
    //   url: "https://facebook.com",
    // },
    // {
    //   id: 12,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: "https://pinterest.com",
    // },
    // {
    //   id: 13,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: "https://dribbble.com",
    // },
    // {
    //   id: 14,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    // },
    // {
    //   id: 15,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
    // },
    // {
    //   id: 16,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: "https://twitter.com",
    // },
    // {
    //   id: 17,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"],
    // },
    // {
    //   id: 18,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    // },
    // {
    //   id: 19,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: "https://dribbble.com",
    // },
    // {
    //   id: 20,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg",
    //   ],
    //   url: "https://facebook.com",
    // },
    // {
    //   id: 21,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: "https://pinterest.com",
    // },
    // {
    //   id: 22,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "#",
    // },
    // {
    //   id: 23,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    // },
  ],
  experience: {
    workingExperience: [
      {
        id: 0,
        year: "2021 - Present",
        position: "Meta",
        company: "Meta",
        details: "",
      },
      {
        id: 1,
        year: "2019 - Present",
        position: "Software Engineer",
        company: "Freelance",
        details:
          "Meticulous and innovative Software Engineer aimed to master latest tools and comprehension of modern practices in the software/application development lifecycle – from concept through delivery of websites/applications with the best user experience.",
      },
      {
        id: 2,
        year: "2018 - 2019",
        position: "Petroleum Engineer/Data Analyst",
        company: "Petrovalues",
        details:
          "Decline curve analysis, Reservoir Characterization, write and code DSU - Website Support.",
      },
      {
        id: 3,
        year: "2016-2018",
        position: "Engineer",
        company: "CTL Thompson",
        details: "Engineer - Data analyst.",
      },
      {
        id: 4,
        year: "2015-2015",
        position: "Engineer / Data Manager",
        company: "Chevron",
        details: "Data Manager - Quality Control - Quality Assurance.",
      },
      {
        id: 5,
        year: "2002-2015",
        position: "Customer Service",
        company: "Various",
        details: "Customer Service - Retail",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2023 - 2025",
        graduation: "Master of Science",
        university: "Regis University",
        details: "Master of Science In Data Science/Machine Learning",
      },
      {
        id: 2,
        year: "2013 - 2016",
        graduation: "Bachelor of Science",
        university: "Colorado School of Mines",
        details: "Bachelor of Science in Petroleum Engineering",
      },
      // {
      //   id: 3,
      //   year: "2012 - 2013",
      //   graduation: "Community College",
      //   university: "College",
      //   details:
      //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      // },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["720-244-XXXX"],
    emailAddress: [""],
    address: "Denver CO - 80227 - USA",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
